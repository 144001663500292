import React from "react";
import Layout from "../components/layout";

const NotFoundPage = ({ location: { pathname } }) => (
  <Layout meta={{ path: pathname }}>
    <section className="min-vh-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-8 col-md-10">
            <article className="article">
              <h1>Page not found</h1>
              <p>Go back home</p>
            </article>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
